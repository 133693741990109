import React from "react"
import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import Img from "gatsby-image"
import { TransitionGroup, CSSTransition } from "react-transition-group"

const StyledModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  padding: 50px;

  /* Animations */
  &.animation-enter {
    opacity: 0;
    .modalContent {
      transform: scale(0.5);
    }
  }
  &.animation-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
    .modalContent {
      transform: scale(1);
      transition: all 300ms ease-out;
    }
  }
  &.animation-exit {
    opacity: 1;
    .modalContent {
      transform: scale(1);
    }
  }
  &.animation-exit-active {
    opacity: 0;
    transition: opacity 150ms ease-in;
    .modalContent {
      transform: scale(0.5);
      transition: all 150ms ease-out;
    }
  }

  .modalContent {
    font-family: "Roboto", sans-serif;
    position: relative;
    padding: 25px;
    border-radius: 4px;
    background: white;
    width: 100%;
    max-width: 500px;
    max-height: calc(100vh - 20px);
    overflow: auto;
    .link {
      display: flex;
      justify-content: flex-end;
      a {
        display: inline-block;
        background: ${props => props.theme.midBlue};
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        padding: 15px;
        border-radius: 40px;
        margin-top: 20px;
        text-align: right;
      }
    }
  }
  .image {
    margin-bottom: 20px;
  }
  .title {
    color: ${props => props.theme.midBlue};
    font-size: 30px;
  }
  .subTitle {
    color: ${props => props.theme.grey};
    font-weight: 700;
    border-bottom: 1px solid ${props => props.theme.grey};
    margin-bottom: 20px;
    padding: 5px 0;
  }
  .details {
    font-family: "Work Sans", sans-serif;
    line-height: 1.5;
  }
  .btnContainer {
    display: flex;
    justify-content: flex-end;
  }
  .closeBtn {
    text-align: right;
    padding: 0;
    background: transparent;
    border: none;
    font-size: 20px;
  }
`

const Modal = props => {
  console.log(props.projectData)
  if (!props.projectData) return null
  return (
    <TransitionGroup component={null}>
      {props.isShowing && (
        <CSSTransition classNames="animation" timeout={300}>
          <StyledModal onClick={() => props.setisShowing(false)}>
            <div
              className="modalContent"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <div className="btnContainer">
                <button
                  onClick={() => props.setisShowing(false)}
                  className="closeBtn"
                >
                  <FaTimes />
                </button>
              </div>
              <div className="image">
                <Img fluid={props.projectData.image} alt="" />
              </div>
              <div className="title">{props.projectData.title}</div>
              <div className="subTitle">{props.projectData.subTitle}</div>
              <p className="details">{props.projectData.overview}</p>
              <div className="link">
                <a
                  href={props.projectData.link}
                  target="blank"
                  className="hoverRise"
                >
                  Visit Site
                </a>
              </div>
            </div>
          </StyledModal>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

export default Modal
