import React, { useEffect } from "react"
import styled from "styled-components"
import headshot from "../images/KyleHeadshot.jpg"
import triangles_right from "../images/triangles_right.png"

const AboutWrapper = styled.div`
  top: 0;
  position: relative;
  padding: 70px 0;
  z-index: 2;

  img.headshot {
    display: block;
    margin: 0 auto;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
    box-shadow: 0px 0px 12px 5px rgba(0, 0, 0, 0.3);
    @media (max-width: 900px) {
      max-width: 400px;
      max-height: 400px;
      margin-bottom: 70px;
    }
  }
  img.trianglesRight {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 300px;
    @media (max-width: 1300px) {
      opacity: 0.2;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 35px;
    position: relative;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }
  .text {
    font-family: "Montserrat", sans-serif;
    p {
      font-family: "Work Sans", sans-serif;
      font-size: 16px;
      margin-bottom: 35px;
      line-height: 2;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const About = () => {
  return (
    <AboutWrapper id="about">
      <img src={triangles_right} className="trianglesRight" alt="" />
      <div className="grid innerContent">
        <div>
          <img src={headshot} className="headshot" alt="" />
        </div>
        <div className="text">
          <div className="sectionTitle">ABOUT ME</div>
          <div className="sectionSubtitle">A bit about my interests</div>
          <p>
            I grew up in Southern California. After graduating from college with
            a BS in Business Administration, I took up coding as hobby to work
            on my problem solving skills. My passion for it quickly grew as it
            became the focus of my educational pursuits. For the past two years,
            I have been working as a freelance developer and a student of the
            internet, improving my skillset daily.
          </p>
          <p>
            Outside of web development, I enjoy all things related to health and
            fitness including cooking, bio-hacking, and playing sports. I also
            enjoy traveling around the world, experiencing new cultures, and
            learning new languages (programming and otherwise).
          </p>
        </div>
      </div>
    </AboutWrapper>
  )
}

export default About
