import { keyframes } from "styled-components"

export const slideDown = keyframes`
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
`

export const slideRight = keyframes`
    0%{
        opacity: 0;
    transform: translateX(-100%);
  }
  100%{
    transform: translateX(-30%);
opacity: auto;  
}

`

export const slideLeft = keyframes`
    0%{
        opacity: 0;
    transform: translateX(100%);
  }
  100%{
    transform: translateX(20%);
opacity: auto;  
}
`

export const fadeIn = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`

export const dropIn = keyframes`
  0%{
    transform: scale(.01);
  }
  100%{
    transform: scale(1);
  }
`
export const formWrapperAnimation = keyframes`
  0%{
    transform: translateX(0)
  }
  100%{
    transform: translateX(1000px)
  }
`

export const formAnimation = keyframes`
  0%{
    transform: scale(1)
  }
  100%{
    transform: scale(0.4)
  }
`
export const envelopeAnimation = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`
