import React from "react"
import styled from "styled-components"
import Slider from "./Slider/Slider"

const ProjectWrapper = styled.div`
  background: linear-gradient(
    to top,
    white,
    ${props => props.theme.darkBlue} calc(36vw + 175px),
    ${props => props.theme.darkBlue}
  );
  .upperSection {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .textGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    @media (max-width: 750px) {
      display: block;
    }
  }
  .companies {
    color: ${props => props.theme.grey};
  }
  .col2 {
    p {
      font-family: "Montserrat", sans-serif;
      font-weight: 500px;
      line-height: 36px;
      letter-spacing: 5px;
      color: #cfcfd7;
    }
  }
`

const Projects = ({ data, populateModal }) => {
  console.log(data)
  const projectArray = [
    {
      title: "Maureen Stapleton",
      subTitle: "ASPEN REALTOR",
      image: data.MSPortfolio.childImageSharp.fluid,
      link: "http://maureenstapleton.net",
      stack:
        "React, JavaScript, Next, Node.js, Express, Contentful CMS, CSS in JS",
      overview:
        "MaureenStapleton.net is the homepage for high-end, Aspen realtor Maureen Stapleton. Built with Next.js and Node.js, this site allows prospective clients to view Maureen's catalogue, past and present, and read her blog.",
    },
    {
      title: "Beyond The Agency",
      subTitle: "MARKETING AGENCY",
      image: data.BTAPortfolio.childImageSharp.fluid,
      link: "http://beyondtheagency.co",
      stack:
        "React, JavaScript, Next, Node.js, Express, Contentful CMS, CSS in JS",
      overview:
        "Beyond The Agency is a Miami-based marketing agency. BeyondTheAgency.co is built on Next.js and Node.js. Server-side rendered React offers improvements to load speed and SEO.",
    },
    {
      title: "Xpire",
      subTitle: "REMINDER APPLICATION",
      image: data.XpirePortfolio.childImageSharp.fluid,
      link: "https://xpire.herokuapp.com",
      stack: "jQuery, Node.js, Express, MySQL, Sequelize",
      overview:
        "Xpire is web application for keeping track of expiration dates of perishable foods. Built with jQuery and Node.js.",
    },
    {
      title: "The New Era of Health",
      subTitle: "HEALTH BLOG AND STORE",
      image: data.NEOHPortfolio.childImageSharp.fluid,
      link: "https://theneweraofhealth.com",
      stack: "React, Node.js, GraphQL-Yoga, Prisma, MySQL",
      overview:
        "TheNewEraofHealth.com is an e-commerce website and blog operated by fitness influencer Adam Lucero. It is built on Gatsby.js and Node.js with a GraphQL API. Customers can learn through the blog and purchase products through the store.",
    },
  ]
  return (
    <ProjectWrapper id="work">
      <div className="upperSection">
        <div className="textGrid innerContent">
          <div className="col1">
            <div className="sectionTitle myWork">MY WORK</div>
            <div className="sectionSubtitle companies">
              Check out a few of my recent projects
            </div>
          </div>
          <div className="col2">
            <p>
              I've worked with a variety of individuals and organizations to
              build custom websites. Each of the projects below involved
              collaboration with a designer and some include work from another
              developer.
            </p>
          </div>
        </div>
      </div>
      <div className="gradient"></div>
      <div className="lowerSection">
        <div className="projects">
          <Slider projectArray={projectArray} populateModal={populateModal} />
        </div>
      </div>
    </ProjectWrapper>
  )
}

export default Projects
