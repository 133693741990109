import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const SlideWrapper = styled.div`
  width: ${props => props.width}%;
  padding-left: ${props => props.padding}%;
  padding-right: ${props => props.padding}%;

  .image {
    width: 100%;
    height: 36vw;
  }
`

const Slide = ({
  project,
  width,
  padding,
  populateModal,
  currentIndex,
  slideIndex,
}) => {
  const handleClick = () => {
    if (currentIndex === slideIndex) {
      populateModal(project)
    }
  }
  return (
    <SlideWrapper width={width} padding={padding} onClick={handleClick}>
      <Img className="image" fluid={project.image} alt="" />
    </SlideWrapper>
  )
}

export default Slide
