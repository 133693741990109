import React, { useEffect } from "react"
import styled from "styled-components"
import Navigation from "./Navigation"
import polygon_right from "../../images/polygon_right.png"
import polygon_left from "../../images/polygon_left.png"
import Hero from "./Hero"

const HeaderWrapper = styled.header`
  width: 100%;
  height: 100vh;
  position: fixed;
  background: radial-gradient(
    500px 700px at 70% 20%,
    #37447d,
    ${props => props.theme.darkBlue}
  );
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
  .polygonLeft {
    position: absolute;
    top: 200px;
    width: 15%;
    transform: translateX(-30%);
    z-index: 1;

    @media (max-width: 1000px) {
      opacity: 0.5;
    }
  }
  .polygonRight {
    position: absolute;
    right: 0px;
    top: 420px;
    width: 23%;
    transform: translateX(20%);
    z-index: 1;

    @media (max-width: 1000px) {
      opacity: 0.5;
    }
  }
`

const Header = props => {
  return (
    <HeaderWrapper id="header">
      <Navigation />
      <img src={polygon_left} alt="" className="polygonLeft" />
      <img src={polygon_right} alt="" className="polygonRight" />
      <Hero />
    </HeaderWrapper>
  )
}

export default Header
