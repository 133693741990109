import React from "react"
import styled from "styled-components"
import { FaRegFileCode, FaNodeJs, FaAtom } from "react-icons/fa"

const StyledBoxes = styled.div`
  width: 100%;
  position: fixed;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  pointer-events: none;
  opacity: 0;
  transform-origin: center;

  .boxes {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 50px;
  }
  .box {
    height: 200px;
    width: 200px;
    background: white;
    box-shadow: 4px 8px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: center;
    .icon {
      font-size: 70px;
      color: ${props => props.theme.midBlue};
      margin-top: 50px;
    }
    .label {
      font-family: "Montserrat", sans-serif;
    }
  }
  .box1 {
    transform: translate3d(-200%, 200%, 0);
  }
  .box2 {
    transform: scale(0);
  }
  .box3 {
    transform: translate3d(200%, -200%, 0);
  }
  @media (max-width: 820px) {
    .boxes {
      width: 100%;
      max-width: 450px;
      padding: 0 20px;
    }
    .box {
      width: 100px;
      height: 100px;

      .icon {
        font-size: 30px;
        margin-top: 15px;
      }
    }
  }
`

const Boxes = () => {
  return (
    <StyledBoxes id="boxes">
      <div className="boxes">
        <div className="box box1">
          <div className="icon">
            <FaRegFileCode />
          </div>

          <div className="label">Front-end Developer</div>
        </div>
        <div className="box box2">
          <div className="icon">
            <FaNodeJs />
          </div>

          <div className="label">Back-end Developer</div>
        </div>
        <div className="box box3">
          <div className="icon">
            <FaAtom />
          </div>
          <div className="label">Innovator</div>
        </div>
      </div>
    </StyledBoxes>
  )
}

export default Boxes
