import React, { Component } from "react"
import styled from "styled-components"
import SliderContent from "./SliderContent"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa"
import { CSSTransition, TransitionGroup } from "react-transition-group"

const StyledSlider = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
  /* top: calc(-25px - 38vw); */
  .prev,
  .next {
    position: absolute;
    cursor: pointer;
    top: calc(18vw - 25px);
    z-index: 1;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.midBlue};
    color: ${props => props.theme.midBlue};
    font-size: 24px;
    line-height: 56px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.3);
    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }
  }
  .prev {
    left: 10%;
  }
  .next {
    right: 10%;
  }
  .projectInfo {
    margin-left: 19%;
    width: 62%;
    padding-top: 15px;
    @media (max-width: 650px) {
      padding-left: 25px;
      padding-right: 25px;
      margin: 0 auto;
      width: 100%;
    }
  }
  .indexBars {
    display: flex;
    margin: 0 auto 15px;
    width: 50%;
    max-width: 250px;
  }
  .indexBar {
    height: 5px;
    flex-grow: 1;
    border-radius: 100px;
    margin-right: 10px;
    background: white;
    transition: all 200ms ease-in-out;
    &.active {
      flex-grow: 2;
      background: ${props => props.theme.midBlue};
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .projectTitleContainer {
    height: 30px;
    position: relative;
  }
  .projectTitle {
    padding: 0;
    background: transparent;
    border: none;
    position: absolute;
    color: ${props => props.theme.midBlue};
    font-family: "Montserrat", sans-serif;
    font-size: 27px;
    font-weight: 700;
    @media (max-width: 450px) {
      font-size: 24px;
    }
  }
  .viewDetailsBtn {
    font-family: "Roboto", sans-serif;
    color: white;
    background: ${props => props.theme.midBlue};
    border: none;
    padding: 10px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 4px;
    margin-top: 20px;
  }
`

export default class Slider extends Component {
  state = {
    progressIndex: 0,
    currentIndex: 2,
    transitionLoading: false,
    clonedProjectArray: [
      this.props.projectArray[this.props.projectArray.length - 2],
      this.props.projectArray[this.props.projectArray.length - 1],
      ...this.props.projectArray,
      this.props.projectArray[0],
      this.props.projectArray[1],
    ],
  }

  componentDidMount() {
    this.transformElement(this.state.currentIndex)
  }

  checkIndex = () => {
    const el = document.getElementById("scwrapper")
    el.classList.remove("animate")
    console.log("this is running")
    let newIndex = this.state.currentIndex
    if (newIndex === this.state.clonedProjectArray.length - 2) {
      console.log("setting to beginning of array")
      newIndex = 2

      this.transformElement(newIndex)
    }
    if (newIndex === 1) {
      console.log("setting to end of array")
      newIndex = this.state.clonedProjectArray.length - 3
      this.transformElement(newIndex)
    }
    this.setState({
      currentIndex: newIndex,
      transitionLoading: false,
    })
  }
  shiftSlide = increase => {
    this.setProgressIndex(increase)
    this.setState({ transitionLoading: true })
    const el = document.getElementById("scwrapper")
    el.classList.add("animate")
    let newIndex
    if (increase) {
      newIndex = this.state.currentIndex + 1
    } else {
      newIndex = this.state.currentIndex - 1
    }
    this.transformElement(newIndex)
    this.setState({
      currentIndex: newIndex,
    })
  }

  transformElement = newIndex => {
    const el = document.getElementById("scwrapper")
    el.style.transform = `translateX(${
      -newIndex * (68 / (this.state.clonedProjectArray.length * 68)) * 100 +
      (16 / (this.state.clonedProjectArray.length * 68)) * 100
    }%)`
  }

  setProgressIndex = increase => {
    let index = this.state.progressIndex
    if (index === this.props.projectArray.length - 1 && increase) {
      index = 0
    } else if (index === 0 && !increase) {
      index = this.props.projectArray.length - 1
    } else {
      if (increase) {
        index += 1
      } else {
        index -= 1
      }
    }
    this.setState({
      progressIndex: index,
    })
  }

  render() {
    return (
      <StyledSlider>
        <button
          className="prev hoverRise"
          onClick={() => this.shiftSlide(false)}
          disabled={this.state.transitionLoading}
        >
          <FaArrowLeft />
        </button>
        <button
          className="next hoverRise"
          onClick={() => this.shiftSlide(true)}
          disabled={this.state.transitionLoading}
        >
          <FaArrowRight />
        </button>
        <SliderContent
          projectArray={this.state.clonedProjectArray}
          SCWidth={this.state.clonedProjectArray.length * 68}
          checkIndex={this.checkIndex}
          currentIndex={this.state.currentIndex}
          populateModal={this.props.populateModal}
        />
        <div className="projectInfo">
          <div className="indexBars">
            {this.props.projectArray.map((project, index) => (
              <div
                key={index}
                className={`indexBar ${
                  index === this.state.progressIndex ? "active" : ""
                }`}
              />
            ))}
          </div>
          <div className="projectTitleContainer">
            <TransitionGroup>
              <CSSTransition
                key={this.state.progressIndex}
                timeout={600}
                classNames="fade"
              >
                <button
                  className="projectTitle"
                  onClick={() =>
                    this.props.populateModal(
                      this.props.projectArray[this.state.progressIndex]
                    )
                  }
                >
                  {this.props.projectArray[this.state.progressIndex].title}
                </button>
              </CSSTransition>
            </TransitionGroup>
          </div>
          <button
            className="viewDetailsBtn hoverRise"
            onClick={() =>
              this.props.populateModal(
                this.props.projectArray[this.state.progressIndex]
              )
            }
          >
            View Details
          </button>
        </div>
      </StyledSlider>
    )
  }
}
