import React from "react"
import styled from "styled-components"
import { FaGithubSquare, FaLinkedin, FaEnvelopeSquare } from "react-icons/fa"

const StyledFooter = styled.footer`
  /* background: #13121d; */
  background: radial-gradient(
    500px 700px at 70% 20%,
    #37447d,
    ${props => props.theme.darkBlue}
  );
  clip-path: polygon(0 140px, 100% 0%, 100% 100%, 0% 100%);
  font-family: "Montserrat", sans-serif;

  .spacer {
    padding-top: 140px;
  }
  .name {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    .first {
      color: white;
    }
    .last {
      color: ${props => props.theme.midBlue};
    }
  }
  .icons {
    display: flex;
    justify-content: center;
    color: ${props => props.theme.grey};
    font-size: 60px;
    padding: 35px 0;
    a {
      color: ${props => props.theme.grey};
    }
    svg {
      margin: 0 20px;
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.grey};
        transition: color 200ms ease;
      }
    }
  }
  .navigation {
    display: flex;
    justify-content: center;
    color: ${props => props.theme.grey};

    border-top: 1px solid ${props => props.theme.grey};
    border-bottom: 1px solid ${props => props.theme.grey};
    margin: 0 20%;
    a {
      display: inline-block;
      margin: 0 20px;
      padding: 35px 0;
      color: ${props => props.theme.grey};
      &:after {
        background: ${props => props.theme.grey};
      }
    }
  }
  .copyright {
    color: ${props => props.theme.grey};
    text-align: center;
    padding: 35px 0;
  }

  @media (max-width: 800px) {
    clip-path: polygon(0 70px, 100% 0%, 100% 100%, 0% 100%);
    .name {
      padding-top: 35px;
    }
    .spacer {
      padding-top: 70px;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <div className="spacer"></div>
      <div className="name">
        <span className="first">KYLE</span> <span className="last">SISSON</span>
      </div>
      <div className="icons">
        <a
          href="https://github.com/ksisson"
          target="blank"
          className="hoverRise"
        >
          <FaGithubSquare />
        </a>
        <a
          href="https://www.linkedin.com/in/kyle-sisson-10950ba7/"
          target="blank"
          className="hoverRise"
        >
          <FaLinkedin />
        </a>
        <a href="mailto:kylegsisson@gmail.com" className="hoverRise">
          <FaEnvelopeSquare />
        </a>
      </div>
      <div className="navigation">
        <a href="#about" className="about hoverUnderline">
          About
        </a>
        <a href="#work" className="work hoverUnderline">
          Work
        </a>
        <a href="#contact" className="contact hoverUnderline">
          Contact
        </a>
      </div>
      <div className="copyright">
        Copyright &copy; {new Date().getFullYear()} | Kyle Sisson | All Rights
        Reserved
      </div>
    </StyledFooter>
  )
}

export default Footer
