import React, { useState } from "react"
import styled from "styled-components"
import contactMe from "../../images/contactMe.png"
import { useFormik } from "formik"
import axios from "axios"
import {
  formAnimation,
  formWrapperAnimation,
  envelopeAnimation,
} from "../Animations"
import { FaEnvelope } from "react-icons/fa"

const StyledContact = styled.div`
  position: relative;
  /* top: -100px; */
  padding-top: 70px;
  overflow: hidden;
  @media (max-width: 800px) {
    padding-bottom: 35px;
  }
  .contactGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    @media (max-width: 850px) {
      grid-template-columns: 1fr;
      justify-content: center;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
    .imageWrapper {
      position: relative;
      @media (max-width: 850px) {
        grid-row: 2;
        text-align: center;
      }
    }
    img {
      position: absolute;
      right: 0;
      top: 15%;
      height: 70%;
      @media (max-width: 850px) {
        position: static;
        height: auto;
        width: 80%;
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }
  .contactHeader {
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    font-weight: 900;
    margin-bottom: 30px;
    span {
      color: ${props => props.theme.midBlue};
    }
  }
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
    max-width: 400px;
    @media (max-width: 510px) {
      display: block;
      input,
      textarea {
        margin-bottom: 10px;
      }
    }
    input,
    textarea {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      font-size: 16px;
      background: #f0eef6;
      width: 100%;
      border: none;
      border-radius: 10px;
      padding: 20px;
    }
    textarea {
      height: 250px;
      resize: none;
    }
    .email,
    .message {
      grid-column: 1/3;
    }
    button {
      font-family: "Roboto", sans-serif;
      font-weight: 700;
      padding: 15px;
      grid-column: 1/3;
      width: 120px;
      justify-self: self-end;
      background: ${props => props.theme.midBlue};
      color: white;
      border: none;
      border-radius: 40px;
    }
  }
  .animate {
    animation: ${formWrapperAnimation} 400ms 500ms ease forwards;
    form {
      animation: ${formAnimation} 400ms ease forwards;
    }
    .envelope {
      animation: ${envelopeAnimation} 200ms 300ms ease forwards;
    }
  }
  .formWrapper {
    position: relative;
  }
  .envelope {
    opacity: 0;
    pointer-events: none;
    font-size: 250px;
    position: absolute;
    top: calc(50% - 125px);
    right: calc(50% - 125px);
  }
`

const Contact = () => {
  const [formSent, setformSent] = useState(false)
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },
    onSubmit: values => {
      // axios.post("http://localhost:3000", values).then(res => console.log(res))
      setformSent(true)
    },
  })
  return (
    <StyledContact className="innerContent" id="contact">
      <div className="contactGrid">
        <div className="imageWrapper">
          <img src={contactMe} alt="" />
        </div>
        <div>
          <div className="contactHeader">
            Contact <span>Me</span>
          </div>
          <div className={formSent ? "formWrapper animate" : "formWrapper"}>
            <form onSubmit={formik.handleSubmit}>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="name"
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="name"
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              <input
                type="text"
                name="email"
                placeholder="Email Address"
                className="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <textarea
                placeholder="Write Here..."
                className="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                name="message"
                id="message"
              ></textarea>

              <button type="submit" className="hoverRise">
                SEND EMAIL
              </button>
            </form>
            <FaEnvelope className="envelope" />
          </div>
        </div>
      </div>
    </StyledContact>
  )
}

export default Contact
