import React, { useState } from "react"
import Header from "../components/Header/Header"
import About from "../components/About"
import Projects from "../components/Projects"
import Contact from "../components/Contact/Contact"
import Footer from "../components/Footer"
import { graphql } from "gatsby"
import Modal from "../components/Modal"
import styled, { ThemeProvider } from "styled-components"
import Boxes from "../components/Boxes"
import handleScroll from "../handleScroll"
import { useEffect } from "react"

const theme = {
  midBlue: "#4679e2",
  darkBlue: "#192249",
  grey: "#9e9e9e",
}

const StyledLayout = styled.div`
  .scrollSpace1 {
    height: calc(795px + 100vh);
  }
  #overlay {
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
  }
`

const IndexPage = ({ data }) => {
  const [isShowing, setisShowing] = useState(false)
  const [projectData, setProjectData] = useState({
    title: "",
    image: "",
    link: "",
    overview: "",
    subTitle: "",
    stack: "",
  })
  const populateModal = projectData => {
    setProjectData(projectData)
    setisShowing(true)
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout>
        <div id="overlay"></div>
        <Modal
          isShowing={isShowing}
          setisShowing={setisShowing}
          projectData={projectData}
        />

        <Header />
        <div className="scrollSpace1"></div>
        {/* Elements outside of the flow */}
        <Boxes />
        {/* Elements outside of the flow */}
        <About />
        <Projects data={data} populateModal={populateModal} />
        <Contact />
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  )
}

export default IndexPage

export const query = graphql`
  query MainQuery {
    MSPortfolio: file(relativePath: { eq: "MSPortfolio.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    BTAPortfolio: file(relativePath: { eq: "BTAPortfolio.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    XpirePortfolio: file(relativePath: { eq: "XpirePortfolio.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    NEOHPortfolio: file(relativePath: { eq: "NEOHPortfolio.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
