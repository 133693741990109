import React from "react"
import styled from "styled-components"

const StyledNavigation = styled.nav`
  color: white;
  font-family: "Montserrat", sans-serif;
  display: grid;
  grid-template-columns: auto 1fr;
  padding-top: 10px;
  padding-bottom: 10px;

  .name {
    display: flex;
    flex-direction: column;

    justify-content: center;
  }
  .firstName {
    font-weight: 700;
    font-size: 30px;
    letter-spacing: 4px;
    span {
      color: ${props => props.theme.midBlue};
    }
  }
  .lastName {
    font-size: 24px;
    /* font-family: "Roboto", sans-serif; */
  }
  .navigation {
    justify-self: right;
    font-family: "Roboto", sans-serif;
  }
  a {
    color: white;
  }
  .about,
  .work,
  .contact {
    display: inline-block;
    margin-left: 35px;
    padding: 30px 0;
    @media (max-width: 420px) {
      margin-left: 20px;
    }
  }
`

const Navigation = () => {
  return (
    <StyledNavigation className="innerContent">
      <div className="name">
        <div className="firstName">
          KYL<span>E</span>
        </div>
        <div className="lastName">SISSON</div>
      </div>
      <div className="navigation">
        <a href="#about" className="about hoverUnderline">
          About
        </a>
        <a href="#work" className="work hoverUnderline">
          Work
        </a>
        <a href="#contact" className="contact hoverUnderline">
          Contact
        </a>
      </div>
    </StyledNavigation>
  )
}

export default Navigation
