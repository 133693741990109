import React from "react"
import styled from "styled-components"
import worker from "../../images/worker.png"
import { dropIn } from "../Animations"

const StyledHero = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto 1fr;
  padding-top: 70px;
  padding-bottom: 170px;
  z-index: 2;
  @media (max-width: 969px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 820px) {
    padding-bottom: 120px;
  }
  @media (max-width: 775px) {
    padding-top: 50px;
    display: block;
    .headerCol2 {
      width: 100%;
      max-width: 365px;
    }
  }
  .headerCol1 {
    .worker {
      height: 80%;
    }
    @media (max-width: 775px) {
      text-align: center;
      .worker {
        width: 28%;
        height: auto;
        opacity: 0.3;
        position: absolute;
        z-index: -1;
        right: 50px;
      }
    }
  }
  .headerCol2 {
    justify-self: end;
  }
  .textContent {
    margin-top: 55px;
  }
  .text {
    font-weight: 400;
    font-size: 42px;
    color: white;
    @media (max-width: 460px) {
      font-size: 38px;
    }
  }
  .text2 {
    font-weight: 600;
    font-size: 68px;
    color: white;
    @media (max-width: 460px) {
      font-size: 60px;
    }
  }
  .text3 {
    font-weight: 700;
    font-size: 75px;
    color: #3bedb1;
    @media (max-width: 460px) {
      font-size: 66px;
    }
  }
  .viewPortfolio {
    display: inline-block;
    text-align: center;
    line-height: 52px;
    width: 140px;
    height: 52px;
    color: white;
    border: none;
    font-weight: 600;
    font-size: 16px;
    border-radius: 4px;
    background-color: ${props => props.theme.midBlue};
    margin-top: 100px;
  }
`

const Hero = () => {
  return (
    <StyledHero className="innerContent">
      <div className="headerCol1">
        <img src={worker} alt="" className="worker" />
      </div>
      <div className="headerCol2">
        <div className="textContent">
          <div className="text">I am Kyle</div>
          <div className="text2">I build amazing</div>
          <div className="text3">Websites</div>
        </div>
        <a href="#work" className="viewPortfolio hoverRise">
          View Portfolio
        </a>
      </div>
    </StyledHero>
  )
}

export default Hero
