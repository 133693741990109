const handleScroll = () => {
  // Define selectors
  const about = document.getElementById("about")
  const header = document.getElementById("header")
  const overlay = document.getElementById("overlay")
  const boxes = document.getElementById("boxes")
  const box1 = document.querySelector(".box1")
  const box2 = document.querySelector(".box2")
  const box3 = document.querySelector(".box3")

  // Static calculations

  let scrolled = window.pageYOffset
  if (scrolled >= 0) {
    if (scrolled > 600) {
      header.style.display = "none"
    } else {
      header.style.display = "block"
    }
    // In the boxes zone
    if (scrolled >= 0 && scrolled < 1200) {
      // Fade in the overlay at 600px
      overlay.style.opacity = scrolled / 600
      boxes.style.opacity = (scrolled - 200) / 600

      if (scrolled > 400) {
        // At 1200px percentageIn = 0
        let percentageIn = 300 - scrolled / 4
        console.log(percentageIn)
        if (percentageIn >= 0 && percentageIn <= 200) {
          box1.style.transform = `translate3d(-${percentageIn}%, ${percentageIn}%, 0)`
          box2.style.transform = `scale(${(200 - percentageIn) / 200})`
          box3.style.transform = `translate3d(${percentageIn}%, -${percentageIn}%, 0)`
        }
      }
    }
    // Not in the about zone
    else {
      overlay.style.opacity = 0
      // scale up and fade out over 200px
      boxes.style.opacity = 1 - (scrolled - 1200) / 300
      boxes.style.transform = `scale(${
        1 + (scrolled - 1200) / 300
      }) translateY(-50%)`
      overlay.style.opacity = 1 - (scrolled - 1400) / 300

      box1.style.transform = `translate3d(0, 0, 0)`
      box2.style.transform = `scale(1)`
      box3.style.transform = `translate3d(0, 0, 0)`
    }
    if (scrolled > 1500) {
      boxes.style.display = "none"
      overlay.style.display = "none"
    } else {
      boxes.style.display = "block"
      overlay.style.display = "block"
    }
  }
}

export default handleScroll
