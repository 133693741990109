import React, { useState } from "react"
import styled from "styled-components"
import Slide from "./Slide"

const SCWrapper = styled.div`
  display: flex;
  width: ${props => props.SCWidth}%;
  &.animate {
    transition: transform ease 400ms;
  }
`

const SliderContent = ({
  checkIndex,
  projectArray,
  SCWidth,
  currentIndex,
  populateModal,
}) => {
  // Width of entire container as a percentage
  //   const containerWidth = projectArray.length * 68

  // Shift the container the width of the image plus the space between images
  //   const translationValue =
  //     -currentIndex * (68 / containerWidth) * 100 + (16 / containerWidth) * 100

  const padding = (3 / SCWidth) * 100
  const width = (68 / SCWidth) * 100
  return (
    <SCWrapper
      id="scwrapper"
      //   translationValue={translationValue}
      SCWidth={SCWidth}
      className="animate"
      onTransitionEnd={checkIndex}
    >
      {projectArray.map((project, index) => (
        <Slide
          key={index}
          project={project}
          padding={padding}
          width={width}
          currentIndex={currentIndex}
          populateModal={populateModal}
          slideIndex={index}
        />
      ))}
    </SCWrapper>
  )
}

export default SliderContent
